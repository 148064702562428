import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer1 from './Footer1'
import { ImgProvider } from '../Assest/ImgProvider';
import { useLocation } from "react-router-dom";
import Loders from './Loders';
import { API } from '../Api';
import { END_POINT } from '../Api/UrlProvider';
export default function PrivacyPolicy({question}) {

  return (
    <>

      {/* <Loders isLoading={isLoading} /> */}
      {question?.length !== 0 ?
      <div>
        {question?.filter((item) => item?.policyType === "privacy").map((item, index) => (
          <div>
            <div className='banner menulaybanner'>
              <div>
                <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} />
              </div>
              <div>
                {/* <div className='headting' dangerouslySetInnerHTML={{ __html: item.policyType }}></div> */}
                <div className='headting' >  Privacy Policy </div>
                <div className='subhedting' dangerouslySetInnerHTML={{ __html: item.subTitle }}></div>
              </div>
              <div> <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} /></div>
            </div>
            <div className=" conatctmanis">
              <div className="boxall mt-20 mb-20">
                <div>
                  <div className="spcett" />
                  <div className="abouttext" style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                  <div className="spcett" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div> 
        :
        <>
       <div className="spcett" />
        <div className="bodylayout">
        <div className="spcett" />
          <div>
            <img src={ImgProvider?.notpost} alt="nopost" />
          </div>
          <div className="spcett" />
          <div className="bimbotmtex"> You’re all caught up</div>
          <div className="spcett" />
          <div className="bimsubtitel"> Come back later for Reminders, health tips, yoga tips,  meditation and weight Posts  </div>
        </div>
        <div className="spcett" />
        </>
      }

    </>
  )
}
