import './App.css';
import './index.css';
import { Route, Routes, BrowserRouter} from "react-router-dom";
import { React, useEffect, useState } from 'react'
import MainPage from './MainPage';
import NoPage from './Pages/NoPage';
import Aboutus from './Pages/Aboutus';
import Contactus from './Pages/Contactus';
import Services from './Pages/Services';
import TermsCondition from './Pages/TermsCondition';

// import Faq from './Pages/Faq';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import CookiePolicy from './Pages/CookiePolicy';
// import Mailer from './Pages/Mailer';
import Header from './Pages/Header'
import Footer from './Pages/Footer'
import { API } from './Api';
import { END_POINT } from './Api/UrlProvider';
import Loders from './Pages/Loders';
function App() {
  const navigate = useNavigate(); // ✅ Now safe to use

  const [question, setquestion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = () => {
    setIsLoading(true);
    API.getAuthAPI(END_POINT?.Allploicy, navigate)
      .then((res) => {
        if (res.data) {
          setquestion(res?.data?.data);
        } else {
          console.error(res.error || "An error occurred");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="body">
      <Loders isLoading={isLoading} />
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/termscondition" element={<TermsCondition question={question} />} />
        {/* <Route path="/mailer" element={<Mailer />} /> */}
        <Route path="/privacypolicy" element={<PrivacyPolicy question={question}/>} />
        <Route path="/cookiepolicy" element={<CookiePolicy question={question}/>} />
        <Route path="/*" element={<NoPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
