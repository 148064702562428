import { useEffect, useState } from "react";
import Header from './Header'
import Footer1 from './Footer1'
import { ImgProvider } from '../Assest/ImgProvider'

export default function Contactus() {
  const words = ["Wellness.", "Relaxation.", "Balance.", "Movement.", "Mindfulness."];
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500); // Change word every 1.5s

    return () => clearInterval(interval);
  }, []);

  // function sendEmail(event) {
  //   event.preventDefault();

  //   const name = document.getElementById('name').value;
  //   const email = document.getElementById('email').value;
  //   const message = document.getElementById('message').value;

  //   fetch('Mailer.php', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urldecoded',
  //     },
  //     body: `name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&message=${encodeURIComponent(message)}`
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.success) {
  //         alert('Your message has been sent!');
  //       } else {
  //         alert('Something went wrong. Please try again.');
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error:', error);
  //       alert('Error sending email.');
  //     });
  // }



  return (
    <>

      <div className='banner menulaybanner'>
        <div>
          <img src={ImgProvider?.contactbanner} alt='servicebnner' height={125} width={105} />
        </div>
        <div>
          <div className='headting'>Contact Us</div>
          <div className='subhedting'>"Get in touch for any assistance"</div>
        </div>
        <div> <img src={ImgProvider?.contactbanner1} alt='servicebnner' height={125} width={105} /></div>
      </div>
      <div class="px-4 md:px-7 lg:px-10 my-10 2xl:my-12 ">
        <div class="container m-auto">
          <div class="bg-cover bg-[100%] xl:bg-[50%_10%] bg-no-repeat rounded-3xl  overflow-hidden  
                     relative flex justify-center" style={{ backgroundImage: `url(${ImgProvider?.contactbax})` }}>
            <div class="w-fit relative  px-5 xs:px-20  md:mt-28  mt-0  ">
              <div class=" md:w-auto w-1/2 absolute items-center text-center   text-white 
          left-[20%] top-[5%] md:top-[5%] md:left-[25%]  ">
                <h1 class="text-3xl font-bold mb-2 mt-6 md:mt-10">Get in Touch</h1>
                <p>Feel free to reach to us any time!</p>
                <div class="md:mt-14 mt-5 relative">
                  <div class="flex  items-center    justify-center md:mb-5 mb-2 gap-1  break-all">
                    <img src={ImgProvider?.addrees} alt='address' /> Griva Digeni, 115, Trident Centre 3101, Limassol, Cyprus
                  </div>
                  <div class="flex items-center    justify-center md:mb-5 mb-2 gap-1 ">
                    <img src={ImgProvider?.emailiii} alt="emailiii" /> support@yogarise.com</div>
                  <div class="flex   items-center    justify-center md:mb-5 mb-2  font-bold  gap-1"> <img src={ImgProvider?.phoneico} alt="phoneno" /> VOTARINI LTD</div>
                </div>
              </div>
              <img alt="Envolope" class="h-[50vh] md:h-[60vh] min-w-auto md:min-w-full mt-10 md:mt-0 " src={ImgProvider?.enlop1} />
            </div>
          </div>
        </div>
      </div>
    
    </>
  )
}
