import React, { useState, useEffect } from 'react'
import { ImgProvider } from '../Assest/ImgProvider';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import Loders from './Loders';

export default function TermsCondition({question}) {
  return (
    <>
      {question?.length !== 0 ?
        <>
          {question?.filter((item) => item?.policyType === "term_condition").map((item, index) => (
            <div>
              <div className='banner menulaybanner'>
                <div>
                  <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} />
                </div>
                <div>
                  {/* <div className='headting' dangerouslySetInnerHTML={{ __html: item.policyType }}></div> */}
                  <div className='headting'> Terms & Condition </div>
                  <div className='subhedting' dangerouslySetInnerHTML={{ __html: item.subTitle }}></div>
                </div>
                <div> <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} /></div>
              </div>
              <div className=" conatctmanis">
                <div className="boxall mt-20 mb-20">
                  <div>
                    <div className="spcett" />
                    <div className="abouttext" style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    <div className="spcett" />
                  </div>
                </div>
              </div>
            </div>

          ))}
        </>
        :
        <>
       <div className="spcett" />
        <div className="bodylayout">
        <div className="spcett" />
          <div>
            <img src={ImgProvider?.notpost} alt="nopost" />
          </div>
          <div className="spcett" />
          <div className="bimbotmtex">You’re all caught up</div>
          <div className="spcett" />
          <div className="bimsubtitel">  Come back later for Reminders, health tips, yoga tips,  meditation and weight Posts  </div>
        </div>
        <div className="spcett" />
        </>
      }
    </> 
  )
}
