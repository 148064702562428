
import React from 'react';
import { BeatLoader } from 'react-spinners'
const Loders = ({ isLoading }) => {
  return (

    <div open={isLoading} >
      <BeatLoader
        cssOverride={{
          height: '100vh',
          width: '100vw',
          display: "flex",
          background: "#06060645",
          justifyContent: "center",
          alignItems: "center",
        }}
        size={30}
        color="#37e6c7"
        speedMultiplier={1}
        loading={isLoading}
      />
    </div>

  );
};

export default Loders;